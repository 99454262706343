<template>
  <van-form @submit="onSubmit">
    <van-cell-group title=" " inset>
      <van-field name="grade" label="评论类型">
        <template #input>
          <van-radio-group v-model="grade" direction="horizontal">
            <van-radio name="GOOD">好</van-radio>
            <van-radio name="GENERAL">中</van-radio>
            <van-radio name="BAD">差</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field
        v-model="content"
        name="content"
        rows="3"
        autosize
        label="评价内容"
        type="textarea"
        placeholder="..."
      />
    </van-cell-group>
    <div style="margin: 16px;">
      <van-button round block type="primary" native-type="submit">
        提交
      </van-button>
    </div>
  </van-form>
</template>

<script>
import { toRefs, reactive, onMounted, inject } from 'vue'
import { Toast, Dialog } from 'vant'

export default {
  setup () {
    const post = inject('post')
    const useRoute = inject('useRoute')
    const useRouter = inject('useRouter')
    // const Cookies = inject('Cookies')
    const state = reactive({
      orderId: useRoute.query.orderId,
      content: '',
      grade: 'GOOD'
    })
    const onSubmit = (values) => {
      Dialog.confirm({
        title: '提示',
        message: '确定这么做吗？'
      }).then(() => {
        values.orderId = state.orderId
        post('/order.addComment', {
          orderId: state.orderId,
          content: state.content,
          grade: state.grade
        }).then(res => {
          if (res.code === 0) {
            Toast.success()
            useRouter.go(-1)
          } else {
            Toast(res.msg)
          }
        })
      }).catch(() => {})
    }
    const init = () => {
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      onSubmit
    }
  }
}
</script>

<style scoped>
</style>
